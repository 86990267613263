var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "staff-induction-main-page" },
    [
      _c(
        "el-form",
        {
          key: "staffInductionMainForm",
          ref: "staffInductionMainForm",
          staticClass: "zwx-form",
          attrs: {
            model: _vm.staffInductionMainForm,
            "label-position": "right"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "condition-row", staticStyle: { display: "flex" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "员工姓名：", "label-width": "70px" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    attrs: { placeholder: "姓名或工号", clearable: "" },
                    model: {
                      value: _vm.staffInductionMainForm.staffName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.staffInductionMainForm,
                          "staffName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "staffInductionMainForm.staffName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "性别：", "label-width": "45px" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.staffInductionMainForm.sexList,
                        callback: function($$v) {
                          _vm.$set(_vm.staffInductionMainForm, "sexList", $$v)
                        },
                        expression: "staffInductionMainForm.sexList"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "zwx-checkbox",
                          attrs: { label: 10010001 }
                        },
                        [_vm._v("男")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "zwx-checkbox",
                          attrs: { label: 10010002 }
                        },
                        [_vm._v("女")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "condition-separator" }),
              _c(
                "el-form-item",
                { attrs: { label: "是否外委：", "label-width": "70px" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.staffInductionMainForm.outWorkerList,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.staffInductionMainForm,
                            "outWorkerList",
                            $$v
                          )
                        },
                        expression: "staffInductionMainForm.outWorkerList"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: true } },
                        [_vm._v("是")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "zwx-checkbox",
                          attrs: { label: false }
                        },
                        [_vm._v("否")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "入职日期：", "label-width": "70px" } },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "135px !important" },
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      "picker-options": _vm.$validate.noBigDate(
                        _vm.$data["staffInductionMainForm"],
                        "hiredateEnd"
                      ),
                      format: "yyyy-MM-dd",
                      type: "date",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "",
                      clearable: ""
                    },
                    model: {
                      value: _vm.staffInductionMainForm.hiredateStart,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.staffInductionMainForm,
                          "hiredateStart",
                          $$v
                        )
                      },
                      expression: "staffInductionMainForm.hiredateStart"
                    }
                  }),
                  _vm._v(" ~ "),
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "135px !important" },
                    attrs: {
                      "picker-options": _vm.$validate.noSmallDate(
                        _vm.$data["staffInductionMainForm"],
                        "hiredateStart"
                      ),
                      "popper-class": "zwx-date-picker-popper",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "",
                      clearable: ""
                    },
                    model: {
                      value: _vm.staffInductionMainForm.hiredateEnd,
                      callback: function($$v) {
                        _vm.$set(_vm.staffInductionMainForm, "hiredateEnd", $$v)
                      },
                      expression: "staffInductionMainForm.hiredateEnd"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "condition-row" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "部门 (车间)：", "label-width": "85px" } },
                [
                  _c("cascader-options-single", {
                    ref: "deptCascaderRef",
                    attrs: {
                      width: "160px",
                      options: _vm.staffInductionMainFormExp.deptOptions,
                      dataNameFiled: "workplaceName",
                      dataCodeFiled: "rid",
                      dataUpCodeFiled: "departmentInfoChildList",
                      expandTrigger: "hover",
                      checkStrictly: true
                    },
                    on: { change: _vm.deptChange },
                    model: {
                      value: _vm.staffInductionMainForm.deptId,
                      callback: function($$v) {
                        _vm.$set(_vm.staffInductionMainForm, "deptId", $$v)
                      },
                      expression: "staffInductionMainForm.deptId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "岗位 (工种)：", "label-width": "85px" } },
                [
                  _c("cascader-options-single", {
                    ref: "stationCascaderRef",
                    attrs: {
                      width: "160px",
                      options: _vm.staffInductionMainFormExp.stationOptions,
                      dataNameFiled: "jobName",
                      dataCodeFiled: "rid",
                      dataUpCodeFiled: "children",
                      expandTrigger: "hover",
                      checkStrictly: true
                    },
                    model: {
                      value: _vm.staffInductionMainForm.stationId,
                      callback: function($$v) {
                        _vm.$set(_vm.staffInductionMainForm, "stationId", $$v)
                      },
                      expression: "staffInductionMainForm.stationId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否接害：", "label-width": "70px" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.staffInductionMainForm.typeList,
                        callback: function($$v) {
                          _vm.$set(_vm.staffInductionMainForm, "typeList", $$v)
                        },
                        expression: "staffInductionMainForm.typeList"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 1 } },
                        [_vm._v("是")]
                      ),
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 2 } },
                        [_vm._v("否")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "condition-row", staticStyle: { display: "flex" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "年度体检：", "label-width": "70px" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.staffInductionMainForm.peList,
                        callback: function($$v) {
                          _vm.$set(_vm.staffInductionMainForm, "peList", $$v)
                        },
                        expression: "staffInductionMainForm.peList"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: true } },
                        [_vm._v("是")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "zwx-checkbox",
                          attrs: { label: false }
                        },
                        [_vm._v("否")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "condition-separator" }),
              _c(
                "el-form-item",
                { attrs: { label: "在职状态：", "label-width": "70px" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.staffInductionMainForm.dutyStatusList,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.staffInductionMainForm,
                            "dutyStatusList",
                            $$v
                          )
                        },
                        expression: "staffInductionMainForm.dutyStatusList"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 1 } },
                        [_vm._v("在职")]
                      ),
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 2 } },
                        [_vm._v("离职")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "zwx-button zwx-button-icontext-28",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: {
                    click: function($event) {
                      return _vm.queryStaffInductionList(1)
                    }
                  }
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "zwx-button zwx-button-icontext-28",
                  attrs: { icon: "el-icon-upload2" },
                  on: { click: _vm.staffInfoImport }
                },
                [_vm._v("导入")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "zwx-button zwx-button-icontext-28",
                  attrs: { icon: "el-icon-plus" },
                  on: { click: _vm.addStaff }
                },
                [_vm._v("添加")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "zwx-button zwx-button-icontext-28",
                  attrs: { icon: "el-icon-download" },
                  on: { click: _vm.recordExport }
                },
                [_vm._v("导出")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-table",
        {
          key: "tdStaffInfoTable",
          staticClass: "zwx-table sortable-table",
          staticStyle: { width: "100%" },
          attrs: {
            "row-key": "rid",
            data: _vm.staffInductionMainFormExp.tableList,
            stripe: "",
            border: ""
          },
          on: { "sort-change": _vm.changeTableSort }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "deptName",
              label: "部门 (车间)",
              width: "200",
              "header-align": "center",
              align: "left",
              sortable: "'custom'"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "stationName",
              label: "岗位 (工种)",
              width: "200",
              "header-align": "center",
              align: "left",
              sortable: "'custom'"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "jobNumber",
              label: "工号",
              width: "120",
              "header-align": "center",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "staffName",
              label: "姓名",
              width: "120",
              "header-align": "center",
              align: "center",
              sortable: "'custom'"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "sex",
              label: "性别",
              width: "80",
              "header-align": "center",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "phone",
              label: "手机号",
              width: "120",
              "header-align": "center",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "hiredate",
              label: "入职日期",
              width: "120",
              "header-align": "center",
              align: "center",
              sortable: "'custom'"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$system.formatDate(
                            scope.row.hiredate,
                            "YYYY-MM-DD"
                          ) || "--"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "outWorker",
              label: "是否外委",
              width: "90",
              "header-align": "center",
              align: "center",
              formatter: _vm.outWorkerFormatter
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "type",
              label: "是否接害",
              width: "90",
              "header-align": "center",
              align: "center",
              formatter: _vm.typeFormatter
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "yearPe",
              label: "是否年度体检",
              width: "120",
              "header-align": "center",
              align: "center",
              formatter: _vm.peRecordIdFormatter
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "在职状态",
              width: "90",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.dutyFormatter(scope.row))
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "header-align": "left",
              align: "left",
              fixed: "right",
              "min-width": 160
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-text-26",
                        attrs: { type: "text", icon: "el-icon-arrow-right" },
                        on: {
                          click: function($event) {
                            return _vm.detail(scope.row)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    ),
                    scope.row.dutyStatus != 2
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: {
                              type: "text",
                              icon: "el-icon-arrow-right"
                            },
                            on: {
                              click: function($event) {
                                return _vm.editStaff(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    scope.row.dutyStatus != 2
                      ? _c(
                          "el-button",
                          {
                            staticClass:
                              "zwx-button zwx-button-text-26 zwx-button-danger",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.delStaff(scope.row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("base-pagination", {
        staticClass: "normal-pagination",
        attrs: {
          parentPage: _vm.staffInductionMainForm.currentPage,
          pageSize: _vm.staffInductionMainFormExp.pageSize,
          total: _vm.staffInductionMainFormExp.total
        },
        on: { currentChange: _vm.queryStaffInductionList }
      }),
      _c("data-import", {
        ref: "staffinfoImportDialogRef",
        staticClass: "staffinfo-import-dialog",
        attrs: {
          title: "批量导入员工",
          accept: ".xls,.xlsx,.XLS,.XLSX",
          "ifshow-upload": _vm.$zwxBase.verifyIsBlank(
            _vm.staffinfoImportDialog.annexName
          ),
          size: 50 * 1024 * 1024,
          sizeInfo: "50M",
          action: _vm.api + "/zky/employer/importStaffInfoExcel-0",
          paramData: _vm.staffinfoImportDialog.paramData,
          successCount: _vm.staffinfoImportDialog.successCount,
          errorCount: _vm.staffinfoImportDialog.errorCount,
          ifShowErrorMsg: _vm.$zwxBase.verifyIsNotBlank(
            _vm.staffinfoImportDialog.exportErrorUid
          ),
          determineBtnDisabled: _vm.staffinfoImportDialog.determineBtnDisabled,
          errorMsg: _vm.staffinfoImportDialog.errorMsg
        },
        on: {
          beforeUpload: _vm.fileBeforeUpload,
          tempDownload: _vm.staffImportTempDownload,
          deletionFile: _vm.deletionFile,
          fileSubmitSuccess: _vm.fileSubmitSuccess,
          fileSubmitError: _vm.fileSubmitError,
          change: _vm.clickFileUpload,
          determine: _vm.staffImportDialogDetermine,
          errorReportDownload: _vm.errorReportDownload,
          cancel: _vm.staffImportDialogCancel,
          close: _vm.staffImportDialogClose
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }