import { render, staticRenderFns } from "./MainPage.vue?vue&type=template&id=69bcfb88&scoped=true"
import script from "./MainPage.vue?vue&type=script&lang=js"
export * from "./MainPage.vue?vue&type=script&lang=js"
import style0 from "./MainPage.vue?vue&type=style&index=0&id=69bcfb88&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69bcfb88",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\product-yangzhou\\view\\ms-vue-occupational-health\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('69bcfb88')) {
      api.createRecord('69bcfb88', component.options)
    } else {
      api.reload('69bcfb88', component.options)
    }
    module.hot.accept("./MainPage.vue?vue&type=template&id=69bcfb88&scoped=true", function () {
      api.rerender('69bcfb88', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/employer/staff_management/staff_induction/MainPage.vue"
export default component.exports