<template>
  <div class="staff-induction-main-page">
    <el-form class="zwx-form" key="staffInductionMainForm" :model="staffInductionMainForm" ref="staffInductionMainForm" label-position="right" @submit.native.prevent>
      <div class="condition-row" style="display:flex">
        <el-form-item label="员工姓名：" label-width="70px">
          <el-input class="zwx-input" placeholder="姓名或工号" v-model.trim="staffInductionMainForm.staffName" clearable></el-input>
        </el-form-item>
        <el-form-item label="性别：" label-width="45px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="staffInductionMainForm.sexList">
            <el-checkbox class="zwx-checkbox" :label="10010001">男</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="10010002">女</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div class="condition-separator"/>
        <el-form-item label="是否外委：" label-width="70px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="staffInductionMainForm.outWorkerList">
            <el-checkbox class="zwx-checkbox" :label="true">是</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="false">否</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="入职日期：" label-width="70px">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 135px !important;"
                          :picker-options="$validate.noBigDate($data['staffInductionMainForm'], 'hiredateEnd')"
                          v-model="staffInductionMainForm.hiredateStart" format="yyyy-MM-dd" type="date"
                          value-format="yyyy-MM-dd" placeholder="" clearable/>
          ~
          <el-date-picker class="zwx-date-picker" style="width: 135px !important;"
                          :picker-options="$validate.noSmallDate($data['staffInductionMainForm'], 'hiredateStart')"
                          popper-class="zwx-date-picker-popper" v-model="staffInductionMainForm.hiredateEnd"
                          format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="" clearable/>
        </el-form-item>
      </div>
      <div class="condition-row">
        <el-form-item label="部门 (车间)：" label-width="85px">
          <cascader-options-single ref="deptCascaderRef" width="160px" :options="staffInductionMainFormExp.deptOptions" v-model="staffInductionMainForm.deptId" dataNameFiled="workplaceName" dataCodeFiled="rid" dataUpCodeFiled="departmentInfoChildList" expandTrigger="hover" :checkStrictly="true" @change="deptChange"></cascader-options-single>
        </el-form-item>
        <el-form-item label="岗位 (工种)：" label-width="85px">
          <cascader-options-single ref="stationCascaderRef" width="160px" :options="staffInductionMainFormExp.stationOptions" v-model="staffInductionMainForm.stationId" dataNameFiled="jobName" dataCodeFiled="rid" dataUpCodeFiled="children" expandTrigger="hover" :checkStrictly="true"></cascader-options-single>
        </el-form-item>
        <el-form-item label="是否接害：" label-width="70px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="staffInductionMainForm.typeList">
            <el-checkbox class="zwx-checkbox" :label="1">是</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="2">否</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>
      <div class="condition-row" style="display:flex">
        <el-form-item label="年度体检：" label-width="70px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="staffInductionMainForm.peList">
            <el-checkbox class="zwx-checkbox" :label="true">是</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="false">否</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div class="condition-separator"/>
        <el-form-item label="在职状态：" label-width="70px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="staffInductionMainForm.dutyStatusList">
            <el-checkbox class="zwx-checkbox" :label="1">在职</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="2">离职</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="queryStaffInductionList(1)">查询</el-button>
        <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2" @click="staffInfoImport">导入</el-button>
        <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addStaff">添加</el-button>
        <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-download" @click="recordExport">导出</el-button>
      </div>
    </el-form>
    <el-table class="zwx-table sortable-table" style="width: 100%" row-key="rid" key="tdStaffInfoTable" :data="staffInductionMainFormExp.tableList" stripe border @sort-change="changeTableSort">
      <el-table-column prop="deptName" label="部门 (车间)" width="200" header-align="center" align="left" sortable="'custom'"></el-table-column>
      <el-table-column prop="stationName" label="岗位 (工种)" width="200" header-align="center" align="left" sortable="'custom'"></el-table-column>
      <el-table-column prop="jobNumber" label="工号" width="120" header-align="center" align="center"></el-table-column>
      <el-table-column prop="staffName" label="姓名" width="120" header-align="center" align="center" sortable="'custom'"></el-table-column>
      <el-table-column prop="sex" label="性别" width="80" header-align="center" align="center"></el-table-column>
      <el-table-column prop="phone" label="手机号" width="120" header-align="center" align="center"></el-table-column>
      <el-table-column prop="hiredate" label="入职日期" width="120" header-align="center" align="center" sortable="'custom'">
        <template slot-scope="scope">
          <span>{{ $system.formatDate(scope.row.hiredate, 'YYYY-MM-DD') || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="outWorker" label="是否外委" width="90" header-align="center" align="center" :formatter="outWorkerFormatter"></el-table-column>
      <el-table-column prop="type" label="是否接害" width="90" header-align="center" align="center" :formatter="typeFormatter"></el-table-column>
      <el-table-column prop="yearPe" label="是否年度体检" width="120" header-align="center" align="center" :formatter="peRecordIdFormatter"></el-table-column>
      <el-table-column label="在职状态" width="90" header-align="center" align="center">
        <template slot-scope="scope">
          <span v-html="dutyFormatter(scope.row)"></span>
        </template>
      </el-table-column>
      <el-table-column label="操作" header-align="left" align="left" fixed="right" :min-width="160">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" @click="detail(scope.row)">查看</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" v-if="scope.row.dutyStatus != 2" @click="editStaff(scope.row)">编辑</el-button>
          <el-button class="zwx-button zwx-button-text-26 zwx-button-danger" type="text" v-if="scope.row.dutyStatus != 2" @click="delStaff(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <base-pagination class="normal-pagination" :parentPage="staffInductionMainForm.currentPage" :pageSize="staffInductionMainFormExp.pageSize" :total="staffInductionMainFormExp.total" @currentChange="queryStaffInductionList" />
    <!--导入弹出框-->
    <data-import class="staffinfo-import-dialog" ref="staffinfoImportDialogRef" title="批量导入员工" accept=".xls,.xlsx,.XLS,.XLSX" :ifshow-upload="$zwxBase.verifyIsBlank(staffinfoImportDialog.annexName)" :size="50 * 1024 * 1024" sizeInfo="50M" :action="api + '/zky/employer/importStaffInfoExcel-0'" :paramData="staffinfoImportDialog.paramData" :successCount="staffinfoImportDialog.successCount" :errorCount="staffinfoImportDialog.errorCount" :ifShowErrorMsg="$zwxBase.verifyIsNotBlank(staffinfoImportDialog.exportErrorUid)" :determineBtnDisabled="staffinfoImportDialog.determineBtnDisabled" :errorMsg="staffinfoImportDialog.errorMsg" @beforeUpload="fileBeforeUpload" @tempDownload="staffImportTempDownload" @deletionFile="deletionFile" @fileSubmitSuccess="fileSubmitSuccess" @fileSubmitError="fileSubmitError" @change="clickFileUpload" @determine="staffImportDialogDetermine" @errorReportDownload="errorReportDownload" @cancel="staffImportDialogCancel" @close="staffImportDialogClose"></data-import>
  </div>
</template>

<script>
export default {
  name: 'StaffInductionMainPage',
  data() {
    return {
      api: this.$store.state.api,
      user: this.$store.state.users,
      employerUuid: '',
      staffInductionMainForm: {
        unitId: '',
        employerUuid: '',
        currentPage: 1, // 指定页码（必填）
        staffName: '', // 员工姓名 or 拼音码（非必填）
        deptId: '', // 部门(车间)Id（非必填）
        stationId: '', // 岗位(工种)Id（非必填）
        jobNumber: '', // 工号（非必填）
        outWorkerList: [], // 是否外委（非必填）
        dutyStatusList: [], // 状态：1:在职 2:离职（非必填）
        typeList: [], // 是否接害
        peList: [], // 是否体检
        sexList: [], // 性别
        hiredateStart:'',
        hiredateEnd:'',
        orderBys:'T.DUTY_STATUS ASC,T.JOB_NUMBER ASC,T.RID DESC'
      },
      staffInductionMainFormExp: {
        deptOptions: [],
        stationOptions: [],
        tableList: [],
        total: 0,
        pageSize: 18,
      },
      staffinfoImportDialog: {
        annexName: '',
        successCount: 0,
        errorCount: 0,
        exportErrorUid: '',
        determineBtnDisabled: false,
        errorMsg: '',
        paramData: {
          userUid: this.$store.state.users.uid,
          unitId: '',
          uuid: '',
        },
      },
    }
  },
  computed: {},
  watch: {},
  activated() {
    // 二级页路由跳转回一级页判断是否需要刷新页面
    if (this.$route.params.fresh == true) {
      this.queryStaffInductionList(1)
    }
  },
  created() { },
  mounted() {
    let url = this.$route.query
    if (this.$zwxBase.verifyIsNotBlank(url.employerUuid)) {
      this.employerUuid = url.employerUuid
      this.staffInductionMainForm.employerUuid = url.employerUuid
    }
    this.getDeptOptions()
    this.queryStaffInductionList(1)
  },
  methods: {

    //选择指定列进行排序
    changeTableSort(column) {
      let prop = null
      let order = null
      if (this.$zwxBase.verifyIsNotBlank(column.order)) { //排序规则不为空
        if (column.prop == 'deptName') {
          prop = 'T.DEPT_ID'
        }
        if (column.prop == 'stationName') {
          prop = 'T.STATION_ID'
        }
        if (column.prop == 'staffName') {
          prop = 'T.STAFF_NAME'
        }
        if (column.prop == 'hiredate') {
          prop = 'T.HIREDATE'
        }
        if (column.order == 'ascending') {
          order = 'ASC'
        }
        if (column.order == 'descending') {
          order = 'DESC'
        }
        this.staffInductionMainForm.orderBys = prop + " " + order
      } else {
        this.staffInductionMainForm.orderBys = 'T.DUTY_STATUS ASC,T.JOB_NUMBER ASC,T.RID DESC' //默认排序规则
      }
      this.queryStaffInductionList(this.staffInductionMainForm.currentPage)
    },

    getDeptOptions() {
      let data = {
        employerUuid: this.employerUuid,
        ifTree: true,
      }
      this.$system.get(
        this.api + '/zky/employer/getDepartmentDropdownList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.staffInductionMainFormExp.deptOptions = data.departmentList
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    getStationOptions(deptId) {
      let data = {
        workplaceId: deptId,
      }
      this.$system.get(
        this.api + '/zky/employer/getJobDropdownList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.staffInductionMainFormExp.stationOptions = data.jobInfoList
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    /**
     * 查询首页列表
     */
    queryStaffInductionList(currentPage) {
      this.staffInductionMainForm.currentPage = currentPage
      let data = {
        ...this.staffInductionMainForm,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/zky/employer/getStaffInfoList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.staffInductionMainFormExp.tableList = data.staffInfoList
            this.staffInductionMainFormExp.total = data.staffInfoCount
            this.staffInductionMainFormExp.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    /**
     * 导出
     */
    recordExport() {
      if (this.staffInductionMainFormExp.total > 0) {
        this.$emit('loading', true)
        let data = {
          ...this.staffInductionMainForm,
        }
        this.$system.postJson(
            this.api + '/zky/employer/exportStaffInfoListExcelUid-1',
            data,
            true,
            true,
            data => {
              if (data.type === '00') {
                this.$system.windowOpenBlank(this.api + '/zky/employer/exportStaffInfoListExcel-0?&uid=' + data.uid)
              } else if (data.type === '99') {
                this.$router.push({name: 'ErrorPage', params: {mess: data.mess}})
              } else {
                this.$system.notify('错误', data.mess, 'error')
              }
              this.$emit('loading', false)
            },
            this.error
        )
      } else {
        this.$system.notify('错误', '列表无数据不能导出', 'error')
      }
    },
    /**
     * 部门车间改变回调
     */
    deptChange(value) {
      this.staffInductionMainForm.stationId = ''
      this.$refs.stationCascaderRef.clear()
      this.getStationOptions(value)
    },
    /**
     * 是否外委格式化
     */
    outWorkerFormatter(row, column, cellValue, index) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue) && cellValue === true) {
        return '是'
      }
      if (this.$zwxBase.verifyIsNotBlank(cellValue) && cellValue === false) {
        return '否'
      }
      return '-'
    },
    /**
     * 是否接害格式化
     */
    typeFormatter(row, column, cellValue, index) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue) && cellValue == 1) {
        return '是'
      }
      if (this.$zwxBase.verifyIsNotBlank(cellValue) && cellValue == 2) {
        return '否'
      }
      return '-'
    },
    /**
     * 是否年度体检格式化
     */
    peRecordIdFormatter(row, column, cellValue, index) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue) && cellValue === true) {
        return '是'
      }
      if (this.$zwxBase.verifyIsNotBlank(cellValue) && cellValue === false) {
        return '否'
      }
      return '否'
    },
    /**
     * 在职状态
     */
    dutyFormatter(row) {
      if (this.$zwxBase.verifyIsNotBlank(row.dutyStatus)) {
        if (row.dutyStatus == 1) {
          return '<span class="zwx-circle-text zwx-circle-finish">在职</span>'
        }
        if (row.dutyStatus == 2) {
          return '<span class="zwx-circle-text zwx-circle-normal">离职</span>'
        }
      }
      return '-'
    },
    /**
     * 查看
     */
    detail(row) {
      this.$router.push({
        name: 'StaffInductionDetailPage',
        params: { type: 'add', employerUuid: this.employerUuid, rid: row.rid },
      })
    },
    /**
     * 添加
     */
    addStaff() {
      this.$router.push({
        name: 'StaffInductionEditPage',
        params: { type: 'add', employerUuid: this.employerUuid },
      })
    },
    /**
     * 编辑
     */
    editStaff(row) {
      this.$router.push({
        name: 'StaffInductionEditPage',
        params: { type: 'edit', employerUuid: this.employerUuid, rid: row.rid},
      })
    },
    /**
     * 删除
     */
    delStaff(row) {
      this.$system.msgbox('', '提示', '是否确认删除？', '确定', '取消', () => {
        let data = {
          rid: row.rid,
        }
        this.$emit('loading', true)
        this.$system.postJson(
          this.api + '/zky/employer/deleteStaffInfoByRid-1',
          data,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type === '00') {
              this.queryStaffInductionList(1)
              this.$system.notify('成功', data.mess, 'success')
            } else {
              this.$system.notify('错误', data.mess, 'error')
            }
          },
          () => {
            this.$emit('loading', false)
            this.$system.notify('错误', '网络连接失败', 'error')
          }
        )
      })
    },
    /**
     * 员工信息导入
     * */
    staffInfoImport() {
      this.$refs.staffinfoImportDialogRef.show(true)
    },
    fileBeforeUpload(value) {
      if (value === false) {
        this.staffinfoImportDialog.determineBtnDisabled = true
      }
    },
    /**
     * 员工导入模板下载
     * */
    staffImportTempDownload() {
      console.log(123);
      let data = {
        employerUuid: this.employerUuid,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/zky/employer/getDeptListAndStationList-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            console.log(1111111);
            this.$system.downloadUrlFile('员工信息导入模板.xls', this.api + '/zky/employer/downloadStaffImportTemplate-0?userUid=' + this.$store.state.users.uid + '&employerUuid=' + this.employerUuid)
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
          this.$emit('loading', false)
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 上传员工信息文件
     * */
    clickFileUpload(showList) {
      showList.forEach(item => {
        let flag = this.staffinfoImportDialog.annexName === item
        if (flag) {
          return
        }
        this.staffinfoImportDialog.annexName = item
      })
      if (this.$zwxBase.verifyIsNotBlank(this.staffinfoImportDialog.annexName)) {
        this.staffinfoImportDialog.errorMsg = ''
      }
    },
    /**
     * 删除员工导入文件
     * */
    deletionFile() {
      this.staffinfoImportDialog.annexName = ''
      this.staffinfoImportDialog.exportErrorUid = ''
      this.staffinfoImportDialog.successCount = 0
      this.staffinfoImportDialog.errorCount = 0
      this.staffinfoImportDialog.determineBtnDisabled = false
    },
    /**
     * 员工信息导入弹框确认
     * */
    staffImportDialogDetermine: function () {
      if (this.$zwxBase.verifyIsBlank(this.staffinfoImportDialog.annexName)) {
        this.staffinfoImportDialog.errorMsg = '请上传文件'
        return
      } else {
        this.staffinfoImportDialog.errorMsg = ''
      }
      this.staffinfoImportDialog.determineBtnDisabled = true
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/zky/employer/staffInfoImportUid-1',
        null,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.staffinfoImportDialog.paramData.uuid = data.uid
            this.staffinfoImportDialog.paramData.employerUuid = this.employerUuid
            this.$refs.staffinfoImportDialogRef.fileSubmit()
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    fileSubmitSuccess(data) {
      this.$emit('loading', false)
      if (data.type === '00') {
        this.staffinfoImportDialog.successCount = data.successCount || 0
        this.staffinfoImportDialog.errorCount = data.errorCount || 0
        this.staffinfoImportDialog.exportErrorUid = data.exportErrorUid || ''
        if (this.$zwxBase.verifyIsBlank(this.staffinfoImportDialog.exportErrorUid)) {
          this.$refs.staffinfoImportDialogRef.show(false)
          this.$system.notify('成功', '导入成功' + this.staffinfoImportDialog.successCount + '条', 'success')
          this.queryStaffInductionList(1)
        }
      } else {
        this.$system.notify('错误', data.mess, 'error')
        this.staffinfoImportDialog.exportErrorUid = data.exportErrorUid || ''
      }
    },
    fileSubmitError(data) {
      this.$emit('loading', false)
      this.$system.notify('错误', data.mess, 'error')
      this.staffinfoImportDialog.exportErrorUid = data.exportErrorUid || ''
    },
    errorReportDownload() {
      this.$system.downloadUrlFile('员工信息错误报告.xls', this.api + '/zky/employer/exportStaffInfoErrorExcel-0?exportErrorUid=' + this.staffinfoImportDialog.exportErrorUid+'&employerUuid='+this.employerUuid)
    },
    /**
     * 员工信息导入弹框取消
     * */
    staffImportDialogCancel: function () {
      this.$refs.staffinfoImportDialogRef.show(false)
    },
    /**
     * 员工信息导入弹框关闭回调
     * */
    staffImportDialogClose() {
      this.staffinfoImportDialog.annexName = ''
      this.staffinfoImportDialog.uid = ''
      this.staffinfoImportDialog.successCount = 0
      this.staffinfoImportDialog.errorCount = 0
      this.staffinfoImportDialog.exportErrorUid = ''
      this.staffinfoImportDialog.paramData.unitId = ''
      this.staffinfoImportDialog.paramData.uuid = ''
      this.staffinfoImportDialog.determineBtnDisabled = false
      this.staffinfoImportDialog.errorMsg = ''
      this.queryStaffInductionList(1)
    },
  },
}
</script>

<style lang="less" scoped>
.staff-induction-main-page {
  .self-adapting-condition {
    max-width: unset !important;
  }

  /deep/ .zwx-circle-normal {
    color: #e53400 !important;
  }

  /deep/ .zwx-circle-normal:before {
    background-color: #e53400 !important;
  }

  /deep/ .zwx-circle-finish {
    color: #15b150 !important;
  }

  /deep/ .zwx-circle-finish:before {
    background-color: #15b150 !important;
  }
}
</style>
